<template>
  <el-dialog title="功能权限管理" :visible.sync="powerVisible" width="700px" :close-on-click-modal="false">
    <div v-if="showOption == true">
      <el-radio-group v-model="option" @change="changeOption">
        <el-radio label="user">用户权限</el-radio>
        <el-radio label="all">查看已有权限（直接授权+角色授权）</el-radio>
      </el-radio-group>
    </div>
    <el-input style="width: 150px; margin-bottom: 10px; margin-top: 10px" placeholder="输入页面名称搜索" size="mini" v-model="SearchName" @change="filterPageData"></el-input>
    <el-table border height="300px" size="mini" :data="pages" highlight-current-row @current-change="pageSelectedChange" expand-on-click-node="false" style="width: 100%">
      <el-table-column label="页面" sortable prop="name" min-width="100" align="left"></el-table-column>
      <el-table-column label="授权访问" sortable width="80" align="center" v-if="option == 'user'">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.enabled" @change="pageChange(scope.row)"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="powerVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  getAuthorityPage,
  saveAuthorityPage,
  getAuthorityUserAllPage,
} from "@/api/setting/member";
export default {
  props: {
    showOption: {
      default: false,
    },
  },
  data () {
    return {
      pages: [],
      powerVisible: false,
      userKey: "",
      selectedPage: "",
      option: "user",
      SearchName: "",
    };
  },
  methods: {
    //外部调用弹出，入口方法
    showManager (userKey) {
      this.pages = [];
      this.option = "user";
      this.userKey = userKey;
      this.powerVisible = true;
      this.SearchName = "";
      this.getPageData(userKey, "");
    },
    //加载左侧列表
    getPageData (userKey, search) {
      let para = {
        uk: userKey,
        search: search,
      };
      if (this.option == "user") {
        getAuthorityPage(para).then((response) => {
          if (response) {
            this.pages = response.data;
          }
        });
      } else {
        getAuthorityUserAllPage(para).then((response) => {
          if (response) {
            this.pages = response.data;
          }
        });
      }
    },

    //左侧列表选择事件
    pageSelectedChange (row) {
      this.selectedPage = row;
    },
    //左侧checkbox变化事件
    pageChange (row) {
      let para = {
        uk: this.userKey,
        pageKey: row.key,
        enabled: row.enabled,
      };
      var msg = row.enabled ? "授权成功" : "已取消授权";
      saveAuthorityPage(para).then((response) => {
        if (response) {
          this.msg.successMsg(msg);
        }
      });
    },
  
    //左侧列表搜索功能
    filterPageData (value) {
      this.getPageData(this.userKey, value);
    },

    //选择器发生变化事件
    changeOption () {
      this.SearchName = "";
      this.getPageData(this.userKey, "");
    },
  },
  mounted () { },
};
</script>
<style>
</style>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #606266;
  line-height: 24px;
  font-size: 14px;
}
</style>