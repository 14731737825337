import request from '@/utils/request'

export function getPageList(data) {
  return request({
    url: 'role/getPageList',
    method: 'post',
    data
  })
}

export function getRoleUsers(data) {
  return request({
    url: 'role/getRoleUsers',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: 'role/delete',
    method: 'post',
    data
  })
}

export function save(data) {
  return request({
    url: 'role/save',
    method: 'post',
    data
  })
}

export function getAll(data) {
  return request({
    url: 'role/getAll',
    method: 'post',
    data
  })
}

export function GetDataRights(data) {
  return request({
    url: 'role/GetDataRights',
    method: 'post',
    data
  })
}

export function SetDataRights(data) {
  return request({
    url: 'role/SetDataRights',
    method: 'post',
    data
  })
}

